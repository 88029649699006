import React, { Fragment } from 'react';

import Warning from '@svg/exclamation.inline.svg';

import styles from './styles.module.css';

const highlight = text => (
  <Fragment>
    {text.split('<highlight>').map((element, index) =>
      index % 2 === 0 ? (
        element
      ) : (
        <b className={styles.highlight} key={index}>
          {element}
        </b>
      )
    )}
  </Fragment>
);

const List = ({ list }) => (
  <ul className={styles.list}>
    {list.map((item, index) => (
      <li key={index} className={styles.item}>
        {highlight(item)}
      </li>
    ))}
  </ul>
);

const Content = ({ paragraphs }) =>
  paragraphs.map((text, index) => (
    <p key={index} className={styles.paragraph}>
      {highlight(text)}
    </p>
  ));

export default ({ title, paragraphs, list, warning }) => (
  <div className={styles.container}>
    <h3 className={styles.title}>{title}</h3>
    <Content paragraphs={paragraphs} />
    {list ? <List list={list} /> : null}
    {warning ? (
      <Warning className={styles.warning} height={16} width={16} />
    ) : null}
  </div>
);
