import React from 'react';
import Body from '@sections/Legal';
import Layout from '@layouts/business';
import SEO from '@components/seo';

export default () => (
  <Layout>
    <SEO
      title="Лицензионная музыка | IMstream"
      description="Лицензионная музыка от IMstream для использования в коммерческих целях. Без выплат РАО и ВОИС."
    />
    <Body />
  </Layout>
);
