import React from 'react';
import Container from '@components/Container';

import Card from './Card';

import styles from './styles.module.css';
import data from './data.json';

export default function() {
  return (
    <section className={styles.container}>
      <Container>
        {data.cards.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </Container>
    </section>
  );
}
