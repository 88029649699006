import React, { Fragment } from 'react';
import Info from './Info';
import CTA from '@components/CallToAction';

export default function() {
  return (
    <Fragment>
      <Info />
      <CTA />
    </Fragment>
  );
}
